import Script from 'next/script';
import { useEffect } from 'react';

const Facebook = ({ url }) => {
  useEffect(() => {
    if (typeof window.FB != 'undefined') {
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div>
      <div id="fb-root"></div>
      {/* START ==> This script is Fallback for instagram embed */}
      <Script id="instagram-embed" async defer src="https://www.instagram.com/embed.js"></Script>
      {/* END ==> */}
      <Script id="facebook-embed" async defer src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2`}></Script>
      <div className="facebook-embed_wrapper">
        <div className="fb-video" data-href={url} data-allowfullscreen="true" data-autoplay="true"></div>
      </div>
    </div>
  );
};

export default Facebook;

//   return (
//     <iframe
//       src={`https://www.facebook.com/plugins/video.php?height=476&href=${url}&show_text=false&width=380&t=0`}
//       width="100%"
//       height="476"
//       style={{ border: 'none', overflow: 'hidden' }}
//       scrolling="no"
//       frameBorder="0"
//       allowFullScreen="true"
//       allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//     ></iframe>
//   );
