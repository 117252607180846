import { useState } from 'react';

const smList = [
  { label: 'Share on Snapchat', icon: 'snapchat.svg', share_base_url: 'snapchat://creativeKitWeb/camera/1?attachmentUrl=' },
  { label: 'Share on Facebook', icon: 'facebook.svg', share_base_url: 'https://www.facebook.com/sharer.php?u=' },
  { label: 'Share on LinkedIn', icon: 'linkedin.svg', share_base_url: 'https://www.linkedin.com/sharing/share-offsite/?url=' },
  { label: 'Share on X', icon: 'x.svg', share_base_url: 'https://x.com/intent/tweet?text=Check%20out%20my%201Links%20Profile%20-%20' },
  { label: 'Share via WhatsApp', icon: 'whatsapp.svg', share_base_url: 'https://wa.me/?text=Check%20out%20my%201Links%20Profile%20-%20' },
  { label: 'Share via Messenger', icon: 'fb-messanger.svg', share_base_url: 'https://www.messenger.com/new' },
  { label: 'Share via Email', icon: 'email.svg', share_base_url: 'mailto:?subject=Check%20out%20my%201Links%20Profile%20-%20&body=Check%20out%20my%201Links%20Profile%20-%20' },
];

export default function ShareProfileModal(props) {
  const { data } = props;

  const [copyText, setCopyText] = useState('Copy');

  const moreOptionClickHandler = async () => {
    try {
      const shareData = {
        title: '1Links App',
        text: 'Check out my 1Links profile',
        url: `https://1links.app/${data.username}`,
      };
      await navigator.share(shareData);
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };
  const copyLinkClickHandler = async () => {
    try {
      let copyData = `https://1links.app/${data.username}`;
      await navigator.clipboard.writeText(copyData);
      setCopyText('Copied');
      setTimeout(() => {
        setCopyText('Copy');
      }, 2000);
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };
  return (
    <div>
      <p className="share_list__title text-center mt-4 mb-0">Share this Profile</p>

      <div>
        <ul className="share_list">
          {smList.map((item) => (
            <li>
              <a href={`${item.share_base_url}https://1links.app/${data.username}`} target="_blank" rel="noopener noreferrer">
                <img className="me-3" src={`/icons/social-media/${item.icon}`} width={24} height={24} alt={item.label} />
                <span className="share_list__text">{item.label}</span>
                <span className="ms-3">
                  <img src="/icons/link-arrow.svg" width={16} height={16} alt="Link Arrow Icon" />
                </span>
              </a>
            </li>
          ))}
          <li>
            <a onClick={moreOptionClickHandler} target="_blank" rel="noopener noreferrer">
              <img className="me-3" src={`/icons/social-media/more.svg`} width={24} height={24} alt="More" />
              <span className="share_list__text">More Options</span>
              <span className="ms-3">
                <img src="/icons/link-arrow.svg" width={16} height={16} alt="Link Arrow Icon" />
              </span>
            </a>
          </li>

          <li>
            <div className="copyclipboard_container" onClick={copyLinkClickHandler}>
              <div className="copyclipboard">
                <div className="copyclipboard__wrapper">
                  <div className="link_box">
                    <img className="me-2 rounded-2" src="/logo-square.svg" width={24} height={24} alt="1Links Logo" />
                    {`1links.app/${data.username}`}
                  </div>
                  <div className="link_box_copy_txt" style={{ color: `${copyText === 'Copied' ? 'rgb(1, 110, 26)' : '#000'}` }}>
                    {copyText}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
