import Image from 'next/image';
import Link from 'next/link';

export default function LogoMultiColor({ color }) {
  return (
    <Link href={'/'} passHref>
      <a>
        <svg width={70} height="21.5" viewBox="0 0 70 21.5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M31.324 1.778C30.694 1.778 30.076 2.656 29.473 4.413C28.878 6.17 28.193 8.891 27.418 12.577C28.094 11.883 28.729 11.05 29.324 10.076C29.919 9.094 30.396 8.144 30.757 7.225C31.522 5.288 31.905 3.896 31.905 3.049C31.905 2.201 31.711 1.778 31.324 1.778ZM24.594 21.322C24.279 21.322 24.063 21.241 23.946 21.078C23.829 20.917 23.738 20.781 23.675 20.673C23.612 20.556 23.563 20.403 23.527 20.214C23.491 20.024 23.459 19.862 23.432 19.727C23.414 19.592 23.4 19.412 23.391 19.186C23.383 18.952 23.378 18.776 23.378 18.659V18.132C23.378 16.393 23.617 14.469 24.094 12.361C24.581 10.252 25.198 8.306 25.945 6.522C26.702 4.738 27.586 3.242 28.594 2.035C29.603 0.827 30.585 0.223 31.541 0.223C32.126 0.223 32.585 0.426 32.919 0.832C33.261 1.228 33.432 1.823 33.432 2.616C33.432 3.4 33.31 4.283 33.067 5.265C32.833 6.238 32.527 7.134 32.148 7.955C31.77 8.765 31.351 9.558 30.892 10.333C30.432 11.099 29.973 11.775 29.513 12.361C28.576 13.55 27.847 14.388 27.324 14.875L26.972 15.226C26.441 16.632 26.175 17.672 26.175 18.348C26.175 19.015 26.333 19.349 26.648 19.349C26.874 19.349 27.49 18.853 28.5 17.862C29.508 16.871 30.432 15.911 31.27 14.983V16.497C29.721 18.164 28.437 19.371 27.418 20.119C26.41 20.858 25.468 21.259 24.594 21.322Z"
            fill={color}
          />
          <path
            d="M32.77 18.24C32.77 18.835 32.928 19.132 33.243 19.132C33.441 19.132 34.063 18.619 35.108 17.592C36.162 16.555 37 15.695 37.621 15.01V16.523C35.846 18.271 34.658 19.398 34.054 19.902C32.955 20.813 32.013 21.286 31.229 21.322C30.833 21.322 30.535 21.146 30.337 20.795C30.139 20.434 30.04 19.974 30.04 19.416C30.04 18.857 30.292 17.546 30.797 15.483C31.301 13.42 31.635 12.172 31.797 11.739C31.968 11.306 32.108 11.005 32.216 10.833C32.324 10.662 32.459 10.527 32.621 10.428C32.784 10.329 32.923 10.252 33.04 10.198C33.167 10.135 33.356 10.09 33.608 10.063C33.959 10.036 34.392 10.023 34.905 10.023C33.482 14.582 32.77 17.321 32.77 18.24ZM35.094 6.427C34.455 6.427 34.135 6.103 34.135 5.454C34.135 5.004 34.301 4.571 34.635 4.157C34.977 3.733 35.378 3.522 35.838 3.522C36.297 3.522 36.639 3.611 36.865 3.792C37.09 3.972 37.202 4.247 37.202 4.616C37.202 4.976 36.986 5.368 36.554 5.792C36.13 6.215 35.644 6.427 35.094 6.427Z"
            fill={color}
          />
          <path
            d="M48.946 15.037V16.551C47.513 18.01 46.243 19.173 45.135 20.037C44.036 20.894 43.301 21.322 42.932 21.322C42.256 21.322 41.919 20.943 41.919 20.187C41.919 19.43 42.085 18.528 42.419 17.483C42.761 16.429 43.099 15.478 43.432 14.631C43.774 13.775 43.946 13.203 43.946 12.915C43.946 12.627 43.891 12.415 43.784 12.279C43.684 12.144 43.563 12.077 43.419 12.077C42.905 12.077 42.351 12.438 41.756 13.158C41.162 13.87 40.63 14.735 40.162 15.753C39.189 17.862 38.549 19.587 38.243 20.93H35.662C35.671 19.957 35.946 18.343 36.486 16.091C37.026 13.838 37.509 12.176 37.932 11.104C38.103 10.671 38.347 10.383 38.662 10.239C38.986 10.095 39.418 10.023 39.959 10.023H40.865C40.207 12.293 39.721 13.929 39.405 14.929C40.008 13.803 40.883 12.658 42.027 11.496C42.585 10.937 43.193 10.478 43.852 10.117C44.509 9.757 45.13 9.577 45.716 9.577C46.599 9.577 47.041 10.104 47.041 11.158C47.041 11.663 46.721 12.861 46.081 14.753C45.45 16.636 45.135 17.677 45.135 17.875C45.135 18.064 45.189 18.159 45.297 18.159C45.414 18.159 45.563 18.101 45.743 17.984C45.932 17.857 46.468 17.375 47.351 16.537C48.234 15.69 48.766 15.19 48.946 15.037Z"
            fill={color}
          />
          <path
            d="M56.594 17.389L56.568 18.389C56.568 18.992 56.649 19.294 56.811 19.294C56.874 19.294 56.928 19.272 56.973 19.227C57.027 19.173 57.234 18.97 57.595 18.619C57.954 18.258 58.333 17.884 58.73 17.497C59.946 16.29 60.775 15.451 61.216 14.983V16.497C59.062 18.623 57.689 19.943 57.095 20.457C56.428 21.034 55.828 21.322 55.297 21.322C54.45 21.322 54.027 20.682 54.027 19.403L54.216 16.834C54.216 16.573 54.144 16.366 53.999 16.213C53.693 15.87 52.77 15.699 51.23 15.699L51.149 16.064C50.545 18.767 49.923 20.389 49.283 20.93H47.189C47.306 19.948 48.369 15.749 50.378 8.333L51.365 4.656C51.788 3.08 52.18 2.071 52.54 1.629C52.765 1.359 53.049 1.214 53.392 1.196C53.743 1.179 54.09 1.17 54.433 1.17C54.783 1.17 55.005 1.188 55.094 1.224C54.752 2.476 54.153 4.549 53.297 7.441C52.45 10.333 52.027 11.793 52.027 11.82L56.729 9.577C57.261 9.577 57.694 9.761 58.027 10.13C58.36 10.5 58.527 10.901 58.527 11.334C58.527 11.757 58.491 12.099 58.418 12.361C58.346 12.613 58.266 12.834 58.176 13.023C58.086 13.212 57.933 13.401 57.716 13.59C57.509 13.771 57.329 13.915 57.175 14.023C57.022 14.132 56.779 14.258 56.446 14.402C55.752 14.699 55.266 14.897 54.986 14.996C54.707 15.086 54.545 15.14 54.5 15.159C54.454 15.177 54.414 15.19 54.378 15.199C55.315 15.199 55.933 15.434 56.23 15.902C56.473 16.281 56.594 16.776 56.594 17.389ZM51.311 14.753H52.216C53.036 14.753 53.756 14.604 54.378 14.307C55.541 13.766 56.122 13.068 56.122 12.212C56.122 11.347 55.77 10.914 55.068 10.914C54.743 10.914 54.293 11.122 53.717 11.536C53.149 11.942 52.617 12.455 52.121 13.077C51.635 13.699 51.365 14.258 51.311 14.753Z"
            fill={color}
          />
          <path
            d="M64.162 20.835C63.856 20.961 63.518 21.074 63.148 21.173C62.788 21.272 62.423 21.322 62.054 21.322C61.693 21.322 61.311 21.286 60.906 21.213C60.509 21.141 60.117 21.024 59.73 20.862C58.847 20.502 58.405 19.88 58.405 18.997C58.405 18.646 58.536 18.245 58.797 17.794C59.058 17.344 59.343 16.947 59.648 16.605C59.964 16.262 60.333 15.87 60.756 15.429C61.18 14.988 61.468 14.672 61.622 14.483C61.378 13.879 61.257 13.222 61.257 12.51C61.257 11.455 61.581 10.585 62.23 9.901C62.878 9.207 63.846 8.86 65.135 8.86C65.703 8.86 65.986 9.018 65.986 9.333C65.986 9.892 65.743 10.329 65.257 10.644C64.779 10.951 64.266 11.104 63.716 11.104C64.022 12.032 64.541 12.865 65.27 13.604C65.54 13.893 65.802 14.167 66.054 14.428C66.639 15.023 66.932 15.537 66.932 15.97C66.932 16.636 66.617 17.402 65.986 18.267L70 14.983V16.47C69.891 16.56 69.622 16.794 69.189 17.172C68.766 17.551 68.454 17.825 68.257 17.997C68.067 18.159 67.752 18.416 67.311 18.767C66.878 19.119 66.527 19.389 66.257 19.578C65.995 19.758 65.657 19.979 65.243 20.24C64.828 20.502 64.469 20.7 64.162 20.835ZM62.203 15.537C62.131 15.618 61.855 15.875 61.378 16.307C60.316 17.254 59.783 17.984 59.783 18.497C59.783 19.002 60.045 19.43 60.568 19.781C61.09 20.124 61.653 20.294 62.257 20.294C62.86 20.294 63.347 20.196 63.716 19.998C64.086 19.79 64.27 19.529 64.27 19.214C64.27 18.898 64.23 18.614 64.149 18.362C64.067 18.11 63.937 17.849 63.757 17.578C63.586 17.298 63.432 17.073 63.297 16.902C63.172 16.722 62.977 16.479 62.716 16.172C62.455 15.857 62.284 15.645 62.203 15.537Z"
            fill={color}
          />
          <path
            d="M11.295 7.867C11.401 7.867 11.453 7.926 11.453 8.044C11.453 8.163 11.449 8.243 11.441 8.285L9.687 16.081C9.619 16.685 9.551 17.069 9.484 17.234C9.416 17.398 9.338 17.48 9.25 17.48H7.964C7.85 17.48 7.793 17.384 7.793 17.189C7.793 16.995 7.839 16.687 7.932 16.264C8.025 15.838 8.122 15.431 8.224 15.042C8.325 14.65 8.441 14.217 8.572 13.745C8.703 13.267 8.933 12.389 9.262 11.11C9.591 9.826 9.761 9.178 9.769 9.165C9.381 9.474 8.946 9.792 8.464 10.122C7.983 10.447 7.66 10.609 7.496 10.609C7.386 10.567 7.331 10.485 7.331 10.362C7.331 10.235 7.46 10.092 7.717 9.932C9.879 8.555 11.071 7.867 11.295 7.867Z"
            fill={color}
          />
          <path
            d="M7.716 4.688H3.168C1.912 4.688 0.894 5.706 0.894 6.962V18.332C0.894 19.589 1.912 20.607 3.168 20.607H14.538C15.795 20.607 16.813 19.589 16.813 18.332V13.784M13.248 0.894H20.07M20.07 0.894V7.716M20.07 0.894L14.08 6.727"
            stroke={color}
            strokeWidth="1.7283965517241378"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </a>
    </Link>
  );
}
