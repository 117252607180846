import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import Logo from '../Client/Components/Logo';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Avatar from '../Client/Components/PublicProfile/Avatar';
import PageItem from '../Client/Components/PublicProfile/PageItem';
import SocialMedia from '../Client/Components/PublicProfile/SocialMedia';
import { getUserPublicProfile } from '../server/Profile';
import LogoMultiColor from '../Client/Components/PublicProfile/LogoMultiColor';
import Popup from '../Client/Components/PublicProfile/Popup';
import { useState } from 'react';

const baseProfileUrl = process.env.NEXT_PUBLIC_MEDIA_URL + '/tr:w-100,f-webp/profile/';
const baseThemeUrl = process.env.NEXT_PUBLIC_MEDIA_URL + '/tr:f-webp/themes/';
const imagePlaceholderBackgroundPath = process.env.NEXT_PUBLIC_MEDIA_URL + '/tr:w-24,f-webp,bl-2/themes/';
const Slug = (props) => {
  console.log('props', props);
  const { userDetails } = props;
  const router = useRouter();

  const [shareModal, setShareModal] = useState(false);
  if (Object.keys(userDetails).length) {
    const theme = userDetails.theme;
    const social_media = userDetails.social_media;
    const feed = userDetails.feed;

    const { slug, preview } = router.query;
    console.log('router.query', router.query);

    // const handleBtnClick = () => {
    //   window.navigator.sendBeacon("/log", { fullName: "sanjay", age: 28 });
    //   // router.push("/login");
    // };

    if (userDetails.is_email_verified === 0) {
      return (
        <div className="username__not_found">
          <style jsx>
            {`
              .username__not_found {
                padding: 80px 0;
                text-align: center;
              }
              h1 {
                font-size: 1.5rem;
              }
            `}
          </style>
          <div className="mb-5">
            <Logo />
          </div>
          <h1 className="mb-3">Page is not verified</h1>
          <p>If you are the owner of this page, please verify your email in order to make it public</p>
          <div>
            <Link href={'/register'} passHref>
              <a className="btn btn-primary">Dashboard</a>
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Head>
          <title>{userDetails.name} | 1LinksApp</title>
          <meta name="title" content={`${userDetails.name} | 1LinksApp`} />

          <meta name="description" content="1LinksApp is a free and elegant link-in-bio tool for creators. Claim your name in less than a minute!" />

          <link rel="shortcut icon" href={userDetails.profile_image === '' ? 'avatar.svg' : baseProfileUrl + userDetails.profile_image} type="image/webp" />
          <link rel="icon" href={userDetails.profile_image === '' ? 'avatar.svg' : baseProfileUrl + userDetails.profile_image} type="image/webp" />
          <link rel="canonical" href={`https://1links.app/${userDetails.username}`} />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={userDetails.name} />
          <meta property="og:url" content={`https://1links.app/${userDetails.username}`} />
          <meta
            property="og:description"
            content={`${userDetails.bio !== '' ? userDetails.bio : '1LinksApp is a free and elegant link-in-bio tool for creators. Claim your name in less than a minute!'}`}
          />
          <meta property="og:image:secure_url" content={userDetails.profile_image === '' ? 'avatar.svg' : baseProfileUrl + userDetails.profile_image} />
          <meta property="og:image" content={userDetails.profile_image === '' ? 'avatar.svg' : baseProfileUrl + userDetails.profile_image} />
          <meta property="profile:username" content={userDetails.username} />

          {/* <!-- Twitter --> */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={userDetails.name} />
          <meta
            property="twitter:description"
            content={`${userDetails.bio !== '' ? userDetails.bio : '1LinksApp is a free and elegant link-in-bio tool for creators. Claim your name in less than a minute!'}`}
          />
          <meta name="twitter:image" content={userDetails.profile_image === '' ? 'avatar.svg' : baseProfileUrl + userDetails.profile_image} />
          <meta name="twitter:url" content={`https://1links.app/${userDetails.username}`} />

          <link rel="preload" as="image" href={userDetails.profile_image === '' ? 'avatar.svg' : baseProfileUrl + userDetails.profile_image} />
          <link rel="icon" type="image/x-icon" href="https://1links.app/favicon.ico" />
          <link rel="apple-touch-icon" sizes="180x180" href="https://1links.app/apple-touch-icon.png" />

          <link rel="manifest" href="https://1links.app/manifest.json" />
          <link rel="dns-prefetch" href="https://matomo.1links.app"></link>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          {
            <style>
              {`
              ${
                preview
                  ? `body::-webkit-scrollbar {
                width: 0;
            }`
                  : ''
              } 
              html, body {
                ${theme.background.background_type === 'FLAT' ? 'background-color: ' + theme.background.primary_color + ' !important;' : ''} 
                -ms-scroll-chaining: none;
                overscroll-behavior: none;
                margin: 0;
                padding: 0;
            }
              main.public-profile-page{
                color : ${theme.background.text_color};
              }

              main.public-profile-page .share-btn{
                // border: 1px solid rgba(255, 255, 255, 0.3);
                // background: linear-gradient(263.81deg, rgba(255, 255, 255, 0.4) 18.8%, rgba(255, 255, 255, 0) 73.34%), rgba(255, 255, 255, 0.25);
                // border-radius: 50%;
                // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03), inset -1px -0.5px 2px rgba(255, 255, 255, 0.4);
                // backdrop-filter: blur(12px);

                border: none;
                background: #FFFFFF;
                border-radius: 50%;
              }


              .page-socialmedia .sm-icon svg{
                fill : ${theme.background.text_color} !important;
              }
              .page-title{
                font-size : 1.2rem;
                font-weight : 700;
              }
              .item_type__HEADER{
                font-size: 16px;
                font-weight: 700;
                text-align:center;
                margin-top:28px;
                color: ${theme.background.text_color}
              }
              .page-item-wrapper{
                ${theme.button.button_type === 'CURVED_FILL' ? 'background-color: ' + theme.button.primary_color + ' !important;' : ''} 
                ${theme.button.css !== '' && theme.button.css !== 'null' ? theme.button.css : ''}
                border-radius: ${theme.button.border_radius} !important;              
                font-weight: 700;
                transition: transform .15s cubic-bezier(.17,.67,.29,2.71) 0s;
              }
              .page-item-wrapper.show-embed:hover {
                transform: none;
              }
              .page-item-wrapper:hover {
                transform: translate3d(0px, 0px, 0px) scale(1.015);
              }
              .page-item-wrapper .page-item-a{
                color: ${theme.button?.text_color ? theme.button?.text_color : '#FFFFFF'}; 
                text-decoration : none;
                min-height:60px;
                align-items: center;
                display: flex;
                justify-content: center;
              }
              .embed-ind-arrow-icon{
                transform: rotate(-90deg);
                transition: all .4s ease-in-out;
              }
              .page-item-wrapper.show-embed .embed-ind-arrow-icon {
                transform: rotate(0deg);
              }
              .public_profile__popup .modal-content{
                overflow : hidden;
              }
              
             
              `}
            </style>
          }
        </Head>
        {preview ? (
          ''
        ) : (
          <Script
            id="matomo-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
      var _paq = window._paq = window._paq || [];
      _paq.push(["setCookieDomain", "*.1links.app"]);
      _paq.push(["setDomains", ["*.1links.app"]]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//analytics.1links.app/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '${userDetails.analytics_site_ID}']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    `,
            }}
          />
        )}

        <div className="container">
          <main className="public-profile-page">
            {theme.background.background_type === 'IMAGE' && (
              <div
                style={{
                  width: '100vw',
                  height: '100%',
                  padding: '8px',
                  position: 'fixed',
                  display: 'block',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: -1,
                  // width: "240px",
                  // height: "240px",
                  transform: 'scale(1.1)',
                }}
              >
                <Image
                  src={baseThemeUrl + '/' + theme.theme_ID + '/' + theme.background.background_image}
                  layout="fill"
                  objectFit="cover"
                  alt="Background Image"
                  placeholder="blur"
                  blurDataURL={`${imagePlaceholderBackgroundPath}/${theme.theme_ID}/${theme.background.background_image}`}
                />
              </div>
            )}
            {/* header share icon */}
            <div className="row justify-content-center">
              <div className="col-md-6 col-xl-4 mb-3">
                <div className="d-flex justify-content-end">
                  <div className="share-btn sm-icon mx-1" onClick={() => setShareModal(true)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_156_54)">
                        <path
                          d="M19 13V15.3077C19 17.3469 17.3469 19 15.3077 19H8.69231C6.65311 19 5 17.3469 5.00001 15.3077L5.00001 13"
                          stroke="#314568"
                          strokeWidth="1.29231"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path d="M8.25 8.76929L12 5.01929L15.75 8.76929" stroke="#314568" strokeWidth="1.29231" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 5.01929V14.2501" stroke="#314568" strokeWidth="1.29231" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_156_54">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 col-xl-4 mb-3">
                <Avatar url={userDetails.profile_image === '' ? 'avatar.svg' : baseProfileUrl + userDetails.profile_image} />
                <h1 className="page-title text-center mt-3">{userDetails.name}</h1>
                <div className="page-bio text-center">{userDetails.bio}</div>
                <div className="page-socialmedia text-center mx-auto mt-3">
                  <SocialMedia data={social_media} />
                </div>
              </div>
            </div>
            {/* Social media icons */}
            {/* Items */}
            <div className="row justify-content-center">
              <div className="col-md-6 col-xl-4">
                {feed.map((item, index) => (
                  <PageItem key={'feed_item__' + index} details={item} />
                ))}
              </div>
            </div>
          </main>
          <div className="text-center  my-3">
            {/* <Link href="/" passHref>
              <a>
                <Image src="/1links.svg" width="70" height={'28'} alt="1Links App logo" />
              </a>
            </Link> */}
            <LogoMultiColor color={theme.background.text_color} />
          </div>
        </div>
        <Popup show={shareModal} handleClose={() => setShareModal(false)} popupFor="SHARE_PROFILE" data={{ username: userDetails.username }} />
        {/* <div dangerouslySetInnerHTML={{ __html: props.instaPost }}></div>
        <div dangerouslySetInnerHTML={{ __html: props.vimeoPost }}></div>
        <button onClick={handleBtnClick}>Click here to read more</button> */}
      </div>
    );
  }

  return (
    <div className="username__not_found">
      <style jsx>
        {`
          .username__not_found {
            padding: 80px 0;
            text-align: center;
          }
          h1 {
            font-size: 1.5rem;
          }
        `}
      </style>
      <div className="mb-5">
        <Logo />
      </div>
      <h1 className="mb-3">The page you’re looking for doesn’t exist.</h1>
      <p>
        Want this to be your username?{' '}
        <Link href={'/register'} passHref>
          <a>Create your 1Links App account now.</a>
        </Link>
      </p>
    </div>
  );
};

export async function getServerSideProps(context) {
  const { slug: username } = context.params;
  const result = await getUserPublicProfile(username);
  // console.log('result ===>', context.req.headers);
  const data = JSON.parse(JSON.stringify(result));
  context.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');
  return {
    props: {
      userDetails: { ...data },
      theme: {},
      feed: {},
      bgColor: 'green',
      instaPost: 'ipost',
      vimeoPost: `<iframe src="https://player.vimeo.com/video/674904051?h=9ef9287e6c&color=58d286" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
    }, // will be passed to the page component as props
  };
}

// export async function getStaticPaths() {
//   return {
//     paths: [
//       { params: { slug: 'abc' } },
//     ],
//     fallback: true, // false or 'blocking'
//   };
// }

// export async function getStaticProps(context) {
//   return {
//     props: {
//       bgColor: "green",
//     }, // will be passed to the page component as props
//   };
// }
export default Slug;
