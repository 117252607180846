import Calendly from './Calendly';
import Facebook from './Facebook';
import Instagram from './Instagram';
import Tiktok from './Tiktok';
import Twitter from './Twitter';
import Youtube from './Youtube';

const Embed = (props) => {
  const { item_subtype, item_value } = props;
  return (
    <>
      {item_subtype === 'FACEBOOK' && <Facebook url={item_value} />}
      {item_subtype === 'INSTAGRAM' && <Instagram url={item_value} />}
      {item_subtype === 'YOUTUBE' && <Youtube url={item_value} />}
      {item_subtype === 'TIKTOK' && <Tiktok url={item_value} />}
      {item_subtype === 'TWITTER' && <Twitter url={item_value} />}
      {item_subtype === 'CALENDLY' && <Calendly url={item_value} />}
    </>
  );
};

export default Embed;
