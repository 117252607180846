import Script from 'next/script';
import { useEffect, useRef } from 'react';

const Tiktok = ({ url }) => {
  useEffect(() => {
    if (typeof window.tiktokEmbed != 'undefined') {
      window.tiktokEmbed.lib.init();
    }
  }, []);

  return (
    <div>
      {/* <iframe width={'100%'} height={"700"}
        // style="width: 100%; height: 100%; display: block; visibility: unset;"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
        src="https://www.tiktok.com/embed/v2/7177063907679309061?lang=en-US"
      ></iframe> */}
      <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@khaby.lame/video/7177063907679309061" data-video-id="7177063907679309061" style={{ maxWidth: '605px', minWidth: '325px' }}>
        <section></section>
      </blockquote>
      <Script key={Math.random()} id="tiktok-embed" async src="https://www.tiktok.com/embed.js"></Script>
    </div>
  );
};

export default Tiktok;
