import { youtubeParse } from "../../../embedParser";

const Youtube = ({ url }) => {
  // youtube short ==> 300 x 600
  // youtube video ==> 560 x 315

  const videoData = youtubeParse(url)
  return (
    <div className="d-flex justify-content-center">
      <iframe
        width={videoData.width}
        height={videoData.height}
        src={`https://www.youtube.com/embed/${videoData.videoID}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Youtube;
