import { Modal } from 'react-bootstrap';
// import { useState } from 'react';

import Embed from './Embed';
import ShareProfileModal from './ShareProfileModal';

export default function Popup(props) {
  const { show, handleClose, item_subtype, item_value, popupFor } = props;
  // const [loader, setLoader] = useState(false);
  // const [appList, setAppList] = useState([]);

  //   const appItemClickHandler = (appdata) => {
  //     handleClose();
  //     handleOpenAppClick(appdata);
  //   };

  let popupInnterContent = null;

  if (popupFor === 'SHARE_PROFILE') {
    let { data } = props;
    popupInnterContent = <ShareProfileModal data={data} />;
  } else {
    popupInnterContent = <Embed item_subtype={item_subtype} item_value={item_value} />;
  }

  return (
    <>
      <Modal className="public_profile__popup scale" show={show} onHide={handleClose} centered>
        <Modal.Body className="p-0">
          {/* <div>
            <img src="/icons/close.svg" /> close
          </div> */}
          {popupInnterContent}
        </Modal.Body>
      </Modal>
    </>
  );
}

Popup.defaultProps = {
  popupFor: 'ITEM_VIEW',
};
