// validate URL
export const LINK_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

// validate Youtube URLs
// https://www.youtube.com/watch?v=iwGFalTRHDA&feature=related
// https://www.youtube.com/channel/UCDZkgJZDyUnqwB070OyP72g
// https://youtube.com/channel/UCDZkgJZDyUnqwB070OyP72g
// https://youtube.com/iwGFalTRHDA
// https://youtube.com/shorts/nTcH07_ohQI?feature=share
// export const YOUTUBE_REGEX = /^(https:\/\/)((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gi;
export const YOUTUBE_REGEX = /^(https:\/\/)((w){3}.)?youtu(be|.be)?(\.com)?\/(.*)/i;
export const YOUTUBE_SHORTS_REGEX = /^(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com)\/(?:shorts)\/(.+)/i;

// validate Instagram URLs
// https://www.instagram.com/p/CoEZZlRveRg/?utm_source=ig_web_copy_link
// https://www.instagram.com/reel/CoH87ARPQD7/?utm_source=ig_web_copy_link
export const INSTAGRAM_REGEX = /^(http(s)?:\/\/)?((w){3}.)?instagram(\.com)?\/(p|reel).+/gi;

// validate TikTok URLs
// https://www.tiktok.com/@bo.on.the.low/video/7194133581931842859?is_from_webapp=1&sender_device=pc
// export const TIKTOK_REGEX = /^(http(s)?:\/\/)?((w){3}.)?tiktok(\.com)?\/(.*)\/(video)\/([0-9]+).+/gi;
export const TIKTOK_REGEX = /^(http(s)?:\/\/)?((w){3}.)?tiktok(\.com)?\/.+/gi;

// validate Twitter URLs
// https://www.tiktok.com/@bo.on.the.low/video/7194133581931842859?is_from_webapp=1&sender_device=pc
// export const TIKTOK_REGEX = /^(http(s)?:\/\/)?((w){3}.)?tiktok(\.com)?\/(.*)\/(video)\/([0-9]+).+/gi;
export const TWITTER_REGEX = /^(?:http?s?:\/\/)?(?:www\.)?(?:twitter\.com)\/?(?:.+)\/(\d+)/i;


// validate Calendly URLs
// https://www.instagram.com/p/CoEZZlRveRg/?utm_source=ig_web_copy_link
// https://www.instagram.com/reel/CoH87ARPQD7/?utm_source=ig_web_copy_link
export const CALENDLY_REGEX = /^(http(s)?:\/\/)?((w){3}.)?calendly(\.com)?\/([a-zA-Z0-9_-]+)\/([a-zA-Z0-9_-]+)?(.+)/gi;
