import Image from 'next/image';
import Link from 'next/link';
const baseItemThumbUrl = process.env.NEXT_PUBLIC_MEDIA_URL + '/tr:w-50,f-webp/feed/thumbnails/';

const LinkItem = (props) => {
  const { ID, item_name, item_value, feed_items_thumbnail, handleEventTrack } = props;

  return (
    <Link href={item_value}>
      <a className="page-item-a" data-id={ID} target="_blank" onClick={() => handleEventTrack(ID)}>
        {feed_items_thumbnail?.thumbnail_url && (
          <div className="item-image-box">
            <Image src={baseItemThumbUrl + feed_items_thumbnail.thumbnail_url} width="44" height="44" alt={item_name} />
          </div>
        )}
        {item_name}
      </a>
    </Link>
  );
};

export default LinkItem;
