import Link from 'next/link';

const SocialMedia = ({ data }) => {
  if (!data) {
    return <></>;
  }

  const socialMediaKeys = [];
  const socialMediaData = {};
  data.forEach((item) => {
    socialMediaKeys.push(item.type);
    socialMediaData[item.type] = item.value;
  });

  return (
    <>
      {socialMediaKeys.length && (
        <>
          {socialMediaKeys.includes('facebook') && (
            <IconLayout linkType="facebook" linkUrl={socialMediaData.facebook}>
              <Facebook />
            </IconLayout>
          )}
          {socialMediaKeys.includes('twitter') && (
            <IconLayout linkType="twitter" linkUrl={`https://twitter.com/${socialMediaData.twitter}`}>
              <Twitter />
            </IconLayout>
          )}
          {socialMediaKeys.includes('instagram') && (
            <IconLayout linkType="instagram" linkUrl={`https://www.instagram.com/${socialMediaData.instagram}`}>
              <Instagram />
            </IconLayout>
          )}
          {socialMediaKeys.includes('linkedin') && (
            <IconLayout linkType="linkedin" linkUrl={socialMediaData.linkedin}>
              <Linkedin />
            </IconLayout>
          )}
          {socialMediaKeys.includes('youtube') && (
            <IconLayout linkType="youtube" linkUrl={socialMediaData.youtube}>
              <Youtube />
            </IconLayout>
          )}
          {socialMediaKeys.includes('whatsapp') && (
            <IconLayout linkType="whatsapp" linkUrl={`https://wa.me/${socialMediaData.whatsapp}`}>
              <Whatsapp />
            </IconLayout>
          )}
          {socialMediaKeys.includes('email') && (
            <IconLayout linkType="email" linkUrl={`mailto:${socialMediaData.email}`}>
              <Email />
            </IconLayout>
          )}
          {socialMediaKeys.includes('website') && (
            <IconLayout linkType="website" linkUrl={`${socialMediaData.website}`}>
              <Website />
            </IconLayout>
          )}
        </>
      )}
    </>
  );
};

const IconLayout = ({ linkUrl, linkType, children }) => {
  const handleEventTrack = () => {
    window._paq.push(['trackEvent', 'social_media', 'Click', linkType, 1]);
  };

  return (
    <div className="sm-icon mx-1">
      <Link href={linkUrl} passHref={true}>
        <a rel="noopener nofollow" target="_blank" onClick={handleEventTrack}>
          {children}
        </a>
      </Link>
    </div>
  );
};

const Facebook = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z" />
    </svg>
  );
};

const Twitter = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.316 6.246c.008.162.011.326.011.488 0 4.99-3.797 10.742-10.74 10.742-2.133 0-4.116-.625-5.787-1.697a7.577 7.577 0 0 0 5.588-1.562 3.779 3.779 0 0 1-3.526-2.621 3.858 3.858 0 0 0 1.705-.065 3.779 3.779 0 0 1-3.028-3.703v-.047a3.766 3.766 0 0 0 1.71.473 3.775 3.775 0 0 1-1.168-5.041 10.716 10.716 0 0 0 7.781 3.945 3.813 3.813 0 0 1-.097-.861 3.773 3.773 0 0 1 3.774-3.773 3.77 3.77 0 0 1 2.756 1.191 7.602 7.602 0 0 0 2.397-.916 3.789 3.789 0 0 1-1.66 2.088 7.55 7.55 0 0 0 2.168-.594 7.623 7.623 0 0 1-1.884 1.953z" />
    </svg>
  );
};

const Linkedin = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zM7.65 13.979H5.706V7.723H7.65v6.256zm-.984-7.024c-.614 0-1.011-.435-1.011-.973 0-.549.409-.971 1.036-.971s1.011.422 1.023.971c0 .538-.396.973-1.048.973zm8.084 7.024h-1.944v-3.467c0-.807-.282-1.355-.985-1.355-.537 0-.856.371-.997.728-.052.127-.065.307-.065.486v3.607H8.814v-4.26c0-.781-.025-1.434-.051-1.996h1.689l.089.869h.039c.256-.408.883-1.01 1.932-1.01 1.279 0 2.238.857 2.238 2.699v3.699z" />
    </svg>
  );
};

const Instagram = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
    </svg>
  );
};

const Youtube = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2.3C.172 2.3 0 3.174 0 10s.172 7.7 10 7.7 10-.874 10-7.7-.172-7.7-10-7.7zm3.205 8.034-4.49 2.096c-.393.182-.715-.022-.715-.456V8.026c0-.433.322-.638.715-.456l4.49 2.096c.393.184.393.484 0 .668z" />
    </svg>
  );
};
const Whatsapp = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="m2.004 22 1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231 4.83 4.83 0 0 0 .383-.22s.043-.028.125-.09c.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z" />
    </svg>
  );
};
const Email = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 10v8h16v-8l-8 3-8-3Zm0-4v2l8 3 8-3V6H4Zm0-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Z" />
    </svg>
  );
};

const Website = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 11.52 11.52" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.76 0a5.76 5.76 0 1 0 0 11.52A5.76 5.76 0 0 0 5.76 0zm3.651 3.285h-1.05a7.732 7.732 0 0 0-.464-1.384c.355.197.685.446.98.741a4.457 4.457 0 0 1 .533.643zm.759 2.475c0 .385-.05.762-.144 1.125H8.585a11.441 11.441 0 0 0 0-2.25h1.441c.095.363.144.74.144 1.125zm-4.41 4.41c-.117 0-.473-.23-.829-.941a5.771 5.771 0 0 1-.377-.995h2.412a5.71 5.71 0 0 1-.377.995c-.355.71-.712.941-.829.941zM4.293 6.885a10.058 10.058 0 0 1 0-2.25h2.933a10.058 10.058 0 0 1 0 2.25H4.293zM1.35 5.76c0-.385.05-.762.144-1.125h1.441a11.441 11.441 0 0 0 0 2.25H1.494A4.462 4.462 0 0 1 1.35 5.76zm4.41-4.41c.117 0 .473.23.829.941.147.295.274.63.377.995H4.554c.104-.364.229-.7.377-.995.355-.71.712-.941.829-.941zm-2.138.551a7.732 7.732 0 0 0-.464 1.384H2.109a4.458 4.458 0 0 1 .532-.643 4.455 4.455 0 0 1 .98-.741zM2.109 8.235h1.05c.121.506.277.972.464 1.384a4.415 4.415 0 0 1-.98-.741 4.457 4.457 0 0 1-.533-.643zm5.789 1.384c.186-.412.343-.877.464-1.384h1.05a4.458 4.458 0 0 1-.532.643 4.455 4.455 0 0 1-.98.741z" />
    </svg>
  );
};

export default SocialMedia;
