import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

const Calendly = ({ url }) => {
  const [loader, setLoader] = useState(true);
  // useEffect(() => {
  //   if (typeof window.Calendly != 'undefined') {
  //     window.Calendly.initInlineWidget({
  //       url: url,
  //       parentElement: document.getElementById('calendly-inline-widget'),
  //       // prefill: {
  //       //   name: 'John Doe',
  //       //   email: 'john@doe2.com',
  //       //   customAnswers: {
  //       //     a1: 'Yes',
  //       //     a2: 'At the Starbucks on 3rd and 16th',
  //       //   },
  //       // },
  //     });
  //   }
  // }, []);

  // return (
  //   <div>
  //     <Script id="calendly-embed" async src="https://assets.calendly.com/assets/external/widget.js"></Script>
  //     {/* <div className="calendly-inline-widget" data-url={url} style="min-width:320px;height:700px;"></div> */}
  //     <div id="calendly-inline-widget" className="calendly-inline-widget" data-url={url} style={{ minWidth: '320px', height: '700px' }}></div>
  //   </div>
  // );

  const onLoad = () => {
    setLoader(false);
  };

  return (
    <div
      className="calendly-inline-widget"
      style={{
        minWidth: '320px',
        height: '700px',
      }}
    >
      {loader && (
        <div className="text-center" style={{ position: 'absolute', left: 'calc(50% - 16px)', top: 'calc(50% - 16px)' }}>
          <Spinner animation="border" role="status" />
        </div>
      )}

      <iframe
        // src="https://calendly.com/sanjaysuthar/30min?embed_domain=localhost%3A4000&amp;embed_type=Inline"
        src={url}
        width="100%"
        height="100%"
        frameBorder="0"
        title="Select a Date &amp; Time - Calendly"
        onLoad={onLoad}
      ></iframe>
    </div>
  );
};

export default Calendly;
