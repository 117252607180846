import Image from 'next/image';
import { useState } from 'react';
import Embed from './Embed';
import LinkItem from './Link';
import Popup from './Popup';

const baseItemThumbUrl = process.env.NEXT_PUBLIC_MEDIA_URL + '/tr:w-50,f-webp/feed/thumbnails/';

const PageItem = ({ details }) => {
  const [embedOpen, setEmbedOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  console.log('details ==>', details);
  const { ID, item_name, item_value, feed_items_thumbnail, item_type, item_subtype, link_behavior } = details;

  const handleEmbedItem = (item_id) => {
    if (link_behavior === 'INLINE') {
      setEmbedOpen((embedOpen) => !embedOpen);
    }
    if (link_behavior === 'POPUP') {
      setPopupOpen(true);
    }
    window._paq.push(['trackEvent', 'page_item', 'Click', item_id, 1]);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };
  const handleEventTrack = (item_id) => {
    window._paq.push(['trackEvent', 'page_item', 'Click', item_id, 1]);
  };

  if (item_type === 'HEADER') {
    return <div className={`item_type__${item_type} mb-3`}>{item_name}</div>;
  }
  if (item_type === 'LINK') {
    return (
      <div className={`page-item-wrapper mb-3 item_type_${item_type}`}>
        <LinkItem ID={ID} item_name={item_name} item_value={item_value} feed_items_thumbnail={feed_items_thumbnail} handleEventTrack={handleEventTrack} />
      </div>
    );
  }

  return (
    <>
      <div className={`page-item-wrapper mb-3 item_type_${item_type} ${embedOpen && 'show-embed'}`}>
        {item_type === 'EMBED' && (
          <>
            {link_behavior === 'NEW_TAB' ? (
              <LinkItem ID={ID} item_name={item_name} item_value={item_value} feed_items_thumbnail={feed_items_thumbnail} handleEventTrack={handleEventTrack} />
            ) : (
              <>
                <a
                  className="page-item-a"
                  onClick={() => {
                    handleEmbedItem(ID);
                  }}
                >
                  {feed_items_thumbnail?.thumbnail_url && (
                    <div className="item-image-box">
                      <Image src={baseItemThumbUrl + feed_items_thumbnail.thumbnail_url} width="44" height="44" alt={item_name} />
                    </div>
                  )}
                  {item_name}
                  <div
                    style={{
                      position: 'absolute',
                      width: '24px',
                      height: '24px',
                      top: '18px',
                      right: '8px',
                    }}
                  >
                    <EmbedIcon />
                  </div>
                </a>
                {embedOpen && <Embed item_subtype={item_subtype} item_value={item_value} />}
              </>
            )}
          </>
        )}
      </div>
      <Popup show={popupOpen} handleClose={handleClose} item_subtype={item_subtype} item_value={item_value} />
    </>
  );
};

const EmbedIcon = () => {
  return (
    <svg className="embed-ind-arrow-icon embed-ind-arrow" fill="#000000" viewBox="0 0 16 16" enableBackground="new 0 0 24 24">
      <path d="M8.006 11c.266 0 .486-.106.695-.323l4.061-4.21A.807.807 0 0013 5.87a.855.855 0 00-.846-.87.856.856 0 00-.626.276L8.006 8.957 4.477 5.276A.87.87 0 003.852 5 .86.86 0 003 5.869c0 .235.087.428.243.599l4.062 4.215c.214.217.434.317.7.317z"></path>
    </svg>
  );
};

export default PageItem;
