import Image from 'next/image';

const Avatar = ({ url }) => {
  return (
    <div
      className="col-auto mx-auto"
      style={{
        position: 'relative',
        width: '96px',
        height: '96px',
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <Image
        className="page-avatar"
        src={url}
        width="120"
        height="120"
        layout="fill"
        objectFit="cover"
        alt={'Image alt caption'}
        placeholder="blur"
        blurDataURL="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAADAAQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAf/xAAbEAADAAMBAQAAAAAAAAAAAAABAgMABAURUf/EABUBAQEAAAAAAAAAAAAAAAAAAAIF/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAECEf/aAAwDAQACEQMRAD8AntO30Y6vPiu0zzlpwnMVAoVUTACgsCfB8xjGT6iW9aBiP//Z"
      />
    </div>
  );
};
export default Avatar;
