import { YOUTUBE_REGEX, YOUTUBE_SHORTS_REGEX } from './Constants/REGEX';

export const youtubeParse = (value) => {
  if (YOUTUBE_SHORTS_REGEX.test(value)) {
    const matchsFound = value.match(YOUTUBE_SHORTS_REGEX);
    return {
      videoID: matchsFound[1],
      type: 'YT_SHORTS',
      width: '300',
      height: '600',
    };
  }

  if (YOUTUBE_REGEX.test(value)) {
    const matchsFound = value.match(YOUTUBE_REGEX);
    const foundYoutubeIDgroup = matchsFound[6];

    if (foundYoutubeIDgroup.startsWith('watch?v=')) {
      const videoID = foundYoutubeIDgroup.replace('watch?v=', '');
      return {
        videoID: videoID,
        type: 'YT_VIDEO',
        width: '570',
        height: '300',
      };
    } else {
      return {
        videoID: foundYoutubeIDgroup,
        type: 'YT_VIDEO',
        width: '570',
        height: '300',
      };
    }
  }
};
