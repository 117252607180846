import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';

const Twitter = ({ url }) => {
  useEffect(() => {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.id = "twitter-embed"
    script.type = 'text/javascript';
    script.src = 'https://platform.twitter.com/widgets.js';
    head.appendChild(script);
    return ()=>{
      document.getElementById("twitter-embed").remove()
    }
  }, []);

  return (
    <div className='text-center'>
      <blockquote className="twitter-tweet">
        <a href={url}><Spinner className='my-5' animation="border" role="status" /></a>
      </blockquote>

      {/* <Script id="twitter-embed" async defer src="https://platform.twitter.com/widgets.js" charset="utf-8"></Script> */}
    </div>
  );
};

export default Twitter;
